var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('section',{staticClass:"bg-home d-flex align-items-center",staticStyle:{"background":"url('images/saas/home-shape.png') center center","height":"auto"},attrs:{"id":"home"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5"},[_c('div',{staticClass:"title-heading margin-top-100"},[_c('h1',{staticClass:"heading mb-3"},[_vm._v("Build fast, released quickly.")]),_c('p',{staticClass:"para-desc mx-auto text-muted"},[_vm._v(" Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap4 html page. ")]),_c('div',{staticClass:"mt-4 pt-2"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#about'),expression:"'#about'"}],staticClass:"btn btn-primary"},[_vm._v("Start Free Trial "),_c('i',{staticClass:"mdi mdi-chevron-right"})])])]),_vm._m(0)])])])]),_vm._m(1),_c('section',{staticClass:"section"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"container mt-100 mt-60"},[_vm._m(5),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12 mt-4"},[_c('Testimonial',{attrs:{"testimonialData":_vm.testimonialData}})],1)])]),_vm._m(6)]),_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"shape overflow-hidden text-footer"},[_c('svg',{attrs:{"viewBox":"0 0 2880 250","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M720 125L2160 0H2880V250H0V125H720Z","fill":"currentColor"}})])])]),_c('Footer'),_c('Switcher'),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#topnav'),expression:"'#topnav'"}],staticClass:"btn btn-icon btn-primary back-to-top",attrs:{"href":"javascript: void(0);","id":"back-to-top"}},[_c('arrow-up-icon',{staticClass:"icons"})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-dashboard"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"images/saas/home.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section bg-light mt-0 mt-md-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"section-title mb-4 pb-2"},[_c('h4',{staticClass:"title mb-4"},[_vm._v("Our Trusted Clients")]),_c('p',{staticClass:"text-muted para-desc mx-auto mb-0"},[_vm._v(" Start working with "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("Landrick")]),_vm._v(" that can provide everything you need to generate awareness, drive traffic, connect. ")])])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"},[_c('img',{staticClass:"avatar avatar-ex-sm",attrs:{"src":"images/client/amazon.svg","alt":""}})]),_c('div',{staticClass:"col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"},[_c('img',{staticClass:"avatar avatar-ex-sm",attrs:{"src":"images/client/google.svg","alt":""}})]),_c('div',{staticClass:"col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"},[_c('img',{staticClass:"avatar avatar-ex-sm",attrs:{"src":"images/client/lenovo.svg","alt":""}})]),_c('div',{staticClass:"col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"},[_c('img',{staticClass:"avatar avatar-ex-sm",attrs:{"src":"images/client/paypal.svg","alt":""}})]),_c('div',{staticClass:"col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"},[_c('img',{staticClass:"avatar avatar-ex-sm",attrs:{"src":"images/client/shopify.svg","alt":""}})]),_c('div',{staticClass:"col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"},[_c('img',{staticClass:"avatar avatar-ex-sm",attrs:{"src":"images/client/spotify.svg","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-12"},[_c('div',{staticClass:"features"},[_c('div',{staticClass:"image position-relative d-inline-block"},[_c('i',{staticClass:"uil uil-airplay h1 text-primary"})]),_c('div',{staticClass:"content mt-4"},[_c('h5',[_vm._v("Easy To Use")]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v("Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper.")])])])]),_c('div',{staticClass:"col-md-4 col-12 mt-5 mt-sm-0"},[_c('div',{staticClass:"features"},[_c('div',{staticClass:"image position-relative d-inline-block"},[_c('i',{staticClass:"uil uil-calendar-alt h1 text-primary"})]),_c('div',{staticClass:"content mt-4"},[_c('h5',[_vm._v("Daily Reports")]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v("Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.")])])])]),_c('div',{staticClass:"col-md-4 col-12 mt-5 mt-sm-0"},[_c('div',{staticClass:"features"},[_c('div',{staticClass:"image position-relative d-inline-block"},[_c('i',{staticClass:"uil uil-clock h1 text-primary"})]),_c('div',{staticClass:"content mt-4"},[_c('h5',[_vm._v("Real Time Zone")]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v("It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-100 mt-60"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('img',{staticClass:"img-fluid shadow rounded",attrs:{"src":"images/saas/classic02.png","alt":""}})]),_c('div',{staticClass:"col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"},[_c('div',{staticClass:"section-title ml-lg-5"},[_c('h4',{staticClass:"title mb-4"},[_vm._v(" Great Product Analytics With Real Problem ")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the distribution of letters visual impact. ")]),_c('ul',{staticClass:"list-unstyled text-muted"},[_c('li',{staticClass:"mb-0"},[_c('span',{staticClass:"text-primary h5 mr-2"},[_c('i',{staticClass:"uil uil-check-circle align-middle"})]),_vm._v("Digital Marketing Solutions for Tomorrow ")]),_c('li',{staticClass:"mb-0"},[_c('span',{staticClass:"text-primary h5 mr-2"},[_c('i',{staticClass:"uil uil-check-circle align-middle"})]),_vm._v("Create your own skin to match your brand ")])]),_c('a',{staticClass:"mt-3 h6 text-primary",attrs:{"href":"javascript:void(0)"}},[_vm._v("Find Out More "),_c('i',{staticClass:"mdi mdi-chevron-right"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-100 mt-60"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"},[_c('div',{staticClass:"section-title mr-lg-5"},[_c('h4',{staticClass:"title mb-4"},[_vm._v("Get Notified About Importent Email")]),_c('p',{staticClass:"text-muted"},[_vm._v(" This prevents repetitive patterns from impairing the overall visual impression and facilitates the comparison of different typefaces. Furthermore, it is advantageous when the dummy text is relatively realistic. ")]),_c('ul',{staticClass:"list-unstyled text-muted"},[_c('li',{staticClass:"mb-0"},[_c('span',{staticClass:"text-primary h5 mr-2"},[_c('i',{staticClass:"uil uil-check-circle align-middle"})]),_vm._v("Digital Marketing Solutions for Tomorrow ")]),_c('li',{staticClass:"mb-0"},[_c('span',{staticClass:"text-primary h5 mr-2"},[_c('i',{staticClass:"uil uil-check-circle align-middle"})]),_vm._v("Create your own skin to match your brand ")])]),_c('a',{staticClass:"mt-3 h6 text-primary",attrs:{"href":"javascript:void(0)"}},[_vm._v("Find Out More "),_c('i',{staticClass:"mdi mdi-chevron-right"})])])]),_c('div',{staticClass:"col-lg-5 col-md-6 order-1 order-md-2"},[_c('img',{attrs:{"src":"images/illustrator/app_development_SVG.svg","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"section-title mb-4 pb-2"},[_c('h4',{staticClass:"title mb-4"},[_vm._v(" Whats Our Clients Said About "),_c('span',{staticClass:"text-primary"},[_vm._v("Landrick")]),_vm._v(" Project ")]),_c('p',{staticClass:"text-muted para-desc mx-auto mb-0"},[_vm._v(" Start working with "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("Landrick")]),_vm._v(" that can provide everything you need to generate awareness, drive traffic, connect. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pb-lg-4 mb-md-5 mb-4 mt-100 mt-60"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"section-title"},[_c('h4',{staticClass:"title mb-4"},[_vm._v(" See everything about your employee at one place. ")]),_c('p',{staticClass:"text-muted para-desc mx-auto mb-0"},[_vm._v(" Start working with "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("Landrick")]),_vm._v(" that can provide everything you need to generate awareness, drive traffic, connect. ")]),_c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"btn btn-primary mt-2 mr-2",attrs:{"href":"javascript:void(0)"}},[_vm._v("Get Started Now")]),_c('a',{staticClass:"btn btn-outline-primary mt-2",attrs:{"href":"javascript:void(0)"}},[_vm._v("Free Trial")])])])])])])
}]

export { render, staticRenderFns }